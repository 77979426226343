import { create } from '@storybook/theming/create'

const theme = create({
  base: 'light',

  brandTitle: 'Allie Design System',
  brandTarget: '_blank',
  brandUrl: 'https://design.allianca.com/',
  brandImage: 'allianca-logo.svg',

  // Typography
  fontBase: '"Inter", sans-serif',
  fontCode: 'monospace',
})

export { theme }
